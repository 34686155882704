






















































import Vue from 'vue'
import { ApiResponse, Sale, Structure } from '@/interfaces'
import { mapState } from 'vuex'

interface DropdownAction {
  action: string
  sale?: Sale
}

interface Badge {
  color: string
  value: string
}

export default Vue.extend({
  name: 'SalesTable',
  props: {
    sales: {
      type: Array,
      required: false,
      default: () => [] as Sale[],
    },
    view: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }
  },
  methods: {
    addonTest(code: number) {
      if (!isNaN(this.addons)) {
        return BigInt(code) & BigInt(this.addons)
      } else {
        return false
      }
    },
    getDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        }).format(date)
      } else {
        return '-'
      }
    },
    getStructureName(structures: Structure[]): string {
      let structureName = ''
      Object.values(structures).forEach((value) => {
        structureName += `${value.name} \n`
      })
      return structureName
    },
    getStructureColor(structures: Structure[]): string {
      let color = '00004f'
      // If there's only one structure, it should be selected by default
      if (Object.keys(structures).length == 1) {
        Object.values(structures).forEach((value) => {
          color = value?.color ?? '00004f'
        })
      }
      return color
    },
    getHour(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(date)
      } else {
        return '-'
      }
    },
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    getHours(start: string, end: string): string {
      let str = '-'

      if (start !== null) {
        str = this.getHour(start)

        if (end !== null) {
          str = `${this.getHour(start)} - ${this.getHour(end)}`
        }
      }

      return str
    },
    handleSaleCommand(command: DropdownAction) {
      // Edit sale
      if (command.action == 'edit' && command.sale) {
        this.editSale(command.sale)
      }

      // Delete sale
      if (command.action == 'delete' && command.sale) {
        this.deleteSale(command.sale)
      }

      // Restore sale
      if (command.action == 'restore' && command.sale) {
        this.restoreSale(command.sale)
      }

      // Delete sale permanently
      if (command.action == 'delete_permanently' && command.sale) {
        this.deleteSalePermanently(command.sale)
      }
    },
    editSale(sale: Sale) {
      if (sale.id) {
        this.$router.push({
          name: 'sale_edit',
          params: {
            saleId: sale?.id?.toString() ?? '',
          },
        })
      }
    },
    deleteSale(sale: Sale) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette vente ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/sale/${sale.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('getSales')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    deleteSalePermanently(sale: Sale) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette vente ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/sale/${sale.id}/permanent`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('getSales')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    restoreSale(sale: Sale) {
      this.$api
        .put(`/sale/${sale.id}/restore`)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })

          this.$emit('getSales')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
          })
        })
    },
    goToSale(row: Sale, column: Record<string, string>, cell: HTMLTableCellElement) {
      if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'sale_edit',
          params: {
            saleId: row.id?.toString() ?? '',
          },
        })
      }
    },
    getSaleTitle(sale: Sale): string {
      return sale.title ?? '-'
    },
    getStatusBadge(status: string): Badge {
      switch (status) {
        case 'en_preparation':
          return {
            color: '#EFE6FD',
            value: 'En préparation',
          }

        case 'publie':
          return {
            color: '#EFE6FD',
            value: 'Publié',
          }

        case 'terminee':
          return {
            color: '#EFE6FD',
            value: 'Terminée',
          }

        case 'comptabilise':
          return {
            color: '#EFE6FD',
            value: 'Comptabilisé',
          }

        default:
          return {
            color: '#EFE6FD',
            value: '-',
          }
      }
    },
  },
})
